import React from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import SmallTitle from "../Components/SmallTitle";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SchoolIcon from "@material-ui/icons/School";
import ResumeItem from "../Components/ResumeItem";

function Resume() {
  const briefcase = <BusinessCenterIcon />;
  const school = <SchoolIcon />;
  return (
    <ResumeStyled>
      <Title title={"Resume"} span={"resume"} />
      <InnerLayout>
        <div className="small-title">
          <SmallTitle icon={briefcase} title={"Experience"} />
        </div>
        <div className="resume-content">
          {/* <ResumeItem
            year={"January 2024 - April 2024"}
            title={"Software Engineer"}
            subTitle={"D2L"}
            textArr={[
              "Completed and shipped to production 3 full features within the Storage Replica extension of Windows Admin Center (WAC), a server/cluster administration and management platform using Angular, TypeScript and PowerShell. Storage Replica enables replication of servers/clusters for disaster recovery:",
              "- Unpartitioned Volume Support: When users create a new partnership between volumes, they are able to select between either traditional or unpartitioned log volumes. Unpartitioned log support has been added and verified for all these types of partnerships.",
              "- Enable and Edit Compression for New Partnerships: When creating new partnership users now have the option to enable or disable as well as edit data compression between the servers.",
              "- Cluster Mounting/Unmounting Support: When users have an existing partnership, they are able edit the settings component to mount/unmount the destination server to a temporary path for test failover. The system automatically fetches all of the available volumes. Cluster mounting/unmounting is available for all types of partnerships.",
            ]}
          /> */}
          <ResumeItem
            year={"September 2023 - Present"}
            title={"Software Engineer"}
            subTitle={"Microsoft"}
            textArr={[
              "Completed and shipped to production 3 full features within the Storage Replica extension of Windows Admin Center (WAC), a server/cluster administration and management platform using Angular, TypeScript and PowerShell. Storage Replica enables replication of servers/clusters for disaster recovery:",
              "- Unpartitioned Volume Support: When users create a new partnership between volumes, they are able to select between either traditional or unpartitioned log volumes. Unpartitioned log support has been added and verified for all these types of partnerships.",
              "- Enable and Edit Compression for New Partnerships: When creating new partnership users now have the option to enable or disable as well as edit data compression between the servers.",
              "- Cluster Mounting/Unmounting Support: When users have an existing partnership, they are able edit the settings component to mount/unmount the destination server to a temporary path for test failover. The system automatically fetches all of the available volumes. Cluster mounting/unmounting is available for all types of partnerships.",
            ]}
          />
          <ResumeItem
            year={"May 2023 - August 2023"}
            title={"Software Engineer"}
            subTitle={"Autodesk"}
            textArr={[
              "Created an extension application for Dynamo Studio, a node-based visual coding language designed for CAD and construction model development applications like Revit and Grasshopper using C#, C++, JavaScript, WebGL and DirectX:",
              "- Developed a 3D Large Model Viewer node extension to view, edit and load 3D models from the web within the desktop application and export them locally into Dynamo’s environment.",
              "- Integrated Autodesk Construction Cloud Platform's authentication services into Dynamo, allowing seamless loading and saving of cloud projects using Autodesk ID SDK.",
              "- Created dynamic model-generation from WebGL specifications to DirectX to extend the Dynamo 3D compiler Abstract Syntax Tree.",
            ]}
          />
          <ResumeItem
            year={"January 2023 - April 2023"}
            title={"Full Stack Engineer"}
            subTitle={"Klue Labs"}
            textArr={[
              "Made an integration web application to enable the Klue competitive intelligence platform to run on the Outreach.io sales execution service using TypeScript, React, Redux Saga, Python, Pulumi, and nginx:",
              "- Created an interactive frontend dashboard to enable users to run market analysis queries directly within Outreach.io and immediately visualize those results.",
              "- Developed a REST API backend server to synchronize client data from Outreach with Klue's platform services, facilitating unique market intelligence insights in the app.",
              "- Deployed the app as Dockerized containers on a Kubernetes cluster using Pulumi and nginx through GitHub actions to facilitate accurate and effective CI/CD development.",
            ]}
          />
          <ResumeItem
            year={"September 2022 - December 2022"}
            title={"Frontend Engineer"}
            subTitle={"Faire"}
            textArr={[
              "Extended the Brand Order section of the Faire platform, a comprehensive wholesale management platform by adding new functionalities directly within the main service as well as add new features to internal analysis tools using TypeScript, React, Jest and MobX:",
              "- Added trace event and statistical analysis functionality within PETE, an internal event tracking and analytics tool to improve the efficiency and comprehensiveness of log events when debugging the main platform.",
              "- Created an Order Triage panel in the Brand Orders platform to organize and manage wholesaler seller’s customer list organized in purchase urgency, recency and frequency.",
              "- Wrote a comprehensive unit and integration testing suite to cover components across both PETE and Order Triage resulting in a 85.7% and 90.1% test coverage respectively.",
            ]}
          />
          <ResumeItem
            year={"May 2022 - August 2022"}
            title={"Software Engineer"}
            subTitle={"Microsoft"}
            textArr={[
              "Developed and deployed to production Azure Stack HCI Sizer, a full-stack cloud utilization visualizer to help companies estimate their server and distributed system costs for the Azure Stack HCI operating system using TypeScript, React, C#, .N, CosmosDB and Redux Saga:",
              "- The website displays user data in a dashboard page along with dynamically generated graphs and recommendations for each project using the users' saved preferences and workloads on the Results page.",
              "- Accessibility standards are maintained throughout the entire application with features such as localization support, light/dark theme, responsive design and complete unit test coverage.",
              "- Wrote a backend API to determine the optimal hardware configurations to reduce server costs while still meeting client specifications.",
            ]}
          />
          <ResumeItem
            year={"Jan 2022 - April 2022"}
            title={"Web Applications Developer"}
            subTitle={"BlackBerry"}
            textArr={[
              "Extended BlackBerry’s CMS UI library to more efficiently create dynamic webpages by creating a variety of components and code optimization using JavaScript, Adobe HTL, Adobe AEM and SCSS:",
              "- Created a frontend component to load and display unique legal patents on webpages as well as edit those documents directly from the CMS system.",
              "- Authored and updated over 60 webpages across all BlackBerry domains ranging from localizing blog sites to updating the main homepage with new product announcements.",
              "- Wrote unit and integration test suites to ensure reliability and code quality for various frontend components.",
            ]}
          />
          <ResumeItem
            year={"Jun 2021 - April 2022"}
            title={"Developer Student Clubs Lead"}
            subTitle={"Google Developers"}
            textArr={[
              "- Founded a new DSC chapter for Wilfrid Laurier University to help students develop their tech skills through hosting events and leading group projects",
              "- Led 4 teams of 4 developers to create sustainable technology solutions for the Google Solutions Challenge",
              "- Organized and planned over 10 speaker events, workshops and information sessions",
            ]}
          />
          <ResumeItem
            year={"May 2021 - Aug 2021"}
            title={"Enterprise Solutions Developer"}
            subTitle={"BlackBerry"}
            textArr={[
              "Developed AppInsight, an internal full-stack interactive web tool to scan APK/IPA files (Android and iOS binary files) for security vulnerabilities to increase the efficiency of the data analytics team using JavaScript, React, Redux Saga and Node.js:",
              "- Created a backend API to enable users to upload/download report files and folders, run security scans and directly navigate project files from the browser.",
              "- Made a responsive and interactive frontend client app to show scan progress, support file and folder navigation and upload/download files from the cloud.",
              "- Ensured that the app automatically fetches and updates project data as soon as it loads as well as ensure that all operations are completed in the background.",
            ]}
          />
          <ResumeItem
            year={"Oct 2020 - Nov 2021"}
            title={"Computer Simulation Research Assistant"}
            subTitle={"Wilfrid Laurier University"}
            textArr={[
              "Designed and developed over 15 accurate simulation programs for kinematic physics experiments for classroom use in virtual physics labs and simulations using JavaScript and HTML5 Canvas:",
              "- Created a thermal expansion and conductivity, a moment of inertia, pipe resonance and many other simulation applications to virtually conduct experiments with multiple materials and conditions.",
              "- Prepared reports of research procedures and data gathered as well as code implementation and design documents for all simulations.",
              "- Awarded a research scholarship in 2021 for developing a multitude of 2D simulation programs for physics course use.",
            ]}
          />
          <ResumeItem
            year={"Sep 2020 - Jul 2021"}
            title={"Application Development Lead"}
            subTitle={"Laurier Computing Society"}
            textArr={[
              "- Led a team of 20 developers to create a Node.js and React platform for creating resume's and adding calendar events",
              "- Created a Node.js REST API for authentication and storage for users' data",
              "- Utilized Agile methodologies for software development through weekly team-wide meetings",
            ]}
          />
          <ResumeItem
            year={"Jul 2020 - May 2021"}
            title={"Full Stack Developer"}
            subTitle={"Laurier Robotics"}
            textArr={[
              "- Created and maintained the official Laurier robotics website using React and Firebase",
              "- Used Firebase to store data and publish the website through Firebase Hosting",
              "- Created email sender feature for Contact Us section as well as a dynamic Events section that automatically updates with new and old events",
              "- Documented technical specifications and project testing methods for future reference and other assignments following standard documentation practices",
            ]}
          />
        </div>
        <div className="small-title u-small-title-margin">
          <SmallTitle icon={school} title={"Education"} />
        </div>
        <div className="resume-content ">
          <ResumeItem
            year={"2019 - 2024"}
            title={"Computer Science and Business Administration Double Degree"}
            subTitle={"Wilfrid Laurier University"}
            textArr={[
              "Current GPA: 11.6 / 12.0",
              "Relevant courses:",
              "    Algorithm Design and Analysis, Data Structures 1 & 2, Software Engineering",
              "    Artificial Intelligence, Computer Networks, Operating Systems",
              "    Object Oriented Programming, Databases 1",
              "    Linear Algebra 1 & 2, Discrete Math, Intro to Mathematical Proofs",
            ]}
          />
        </div>
      </InnerLayout>
    </ResumeStyled>
  );
}

const ResumeStyled = styled.section`
  .small-title {
    padding-bottom: 3rem;
  }
  .u-small-title-margin {
    margin-top: 4rem;
  }

  .resume-content {
    border-left: 2px solid var(--border-color);
  }
`;
export default Resume;
